import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import clsx from "clsx";
import { useEffect } from "react";
import { useRouteBodyClasses, useIsDesktop } from "~/lib/hooks";

export function Viewport({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const isDesktop = useIsDesktop();
  const bodyClasses = useRouteBodyClasses();

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    Keyboard.addListener("keyboardDidShow", () => {
      const focusedInput = document.activeElement;
      focusedInput?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    });
  }, []);

  return (
    <div className="flex h-svh items-center justify-center viewport-short:items-start">
      <div
        className={clsx(
          "relative h-full w-full outline-mentory-grey-4 sm:-mt-5 sm:h-[810px] sm:w-[432px] sm:overflow-y-auto sm:rounded-xl sm:outline sm:outline-8 viewport-short:mt-0 viewport-short:h-full viewport-short:rounded-none viewport-short:shadow-md viewport-short:outline-none",
          isDesktop ? bodyClasses : [],
        )}
      >
        {children}
      </div>
    </div>
  );
}
