import {
  ActionIcon,
  Anchor,
  Button,
  Input,
  Select,
  TagsInput,
  TextInput,
  Textarea,
  createTheme,
} from "@mantine/core";

export default createTheme({
  primaryShade: 6,
  primaryColor: "blue",
  colors: {
    blue: [
      "#edfbff",
      "#d6f5ff",
      "#b5f0ff",
      "#48d9ff",
      "#1ebdff",
      "#06a1ff",
      "#0089f7",
      "#086bc5",
      "#0d5c9b",
      "#0e375d",
    ],
    mentoryGreen: [
      "#e4fff2",
      "#d1fae7",
      "#a5f3ce",
      "#77edb3",
      "#50e79d",
      "#37e48f",
      "#26e287",
      "#14c873",
      "#00b365",
      "#009a55",
    ],
    "mentory-grey": [
      "#ffffff",
      "#eef3f7",
      "#e6eaed",
      "#a3abb1",
      "#70828f",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
      "#ffffff",
    ],
  },
  components: {
    TextInput: TextInput.extend({
      defaultProps: {
        size: "md",
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        size: "md",
      },
    }),
    TagsInput: TagsInput.extend({
      defaultProps: {
        size: "md",
      },
    }),
    Select: Select.extend({
      defaultProps: {
        size: "md",
        classNames: {
          input: "!border-none",
        },
      },
    }),
    InputWrapper: Input.Wrapper.extend({
      defaultProps: {
        size: "md",
      },
    }),
    Button: Button.extend({
      defaultProps: {
        variant: "filled",
        fw: "600",
        radius: "xl",
        fz: "md",
        size: "lg",
        classNames: {
          root: "data-[variant=filled]:shadow-[1px_2px_12px_0_rgba(23,46,89,0.25)] data-[variant=light]:bg-mentory-grey-1 data-[variant=light]:text-mentory-dark",
        },
        styles: {},
      },
    }),
    ActionIcon: ActionIcon.extend({
      classNames: {
        root: "data-[variant=light]:bg-mentory-grey-1 data-[variant=light]:text-mentory-dark data-[variant=white]:text-mentory-dark",
      },
    }),
    Anchor: Anchor.extend({
      defaultProps: {
        underline: "never",
      },
    }),
  },
});
