import { useEffect, useState } from "react";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { useLocation, useNavigate, useNavigation } from "@remix-run/react";
import { request } from "~/lib/request";
import { toast } from "sonner";
import { Text } from "@mantine/core";
import { Capacitor } from "@capacitor/core";
import { useUser } from "~/lib/hooks";

export default function NotificationsListener() {
  useNavigateFromNotification();
  useUpdateFcmToken();

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    const listener = FirebaseMessaging.addListener(
      "notificationReceived",
      ({ notification }) => {
        const data = (notification.data as Record<string, string> | null) ?? {};

        if (
          data.route.startsWith("/dashboard/chat") &&
          data.route === location.pathname
        ) {
          // If the chat is already open, do not show a notification
          return;
        }

        toast.custom(
          (t) => (
            <div className="safe-top">
              <button
                className="block w-full rounded-lg border-0 bg-white px-4 py-3 text-left shadow-card"
                onClick={() => {
                  navigate(data.route);
                  toast.dismiss(t);
                }}
              >
                <Text fz="sm" fw={600}>
                  {notification.title}
                </Text>
                <Text fz="sm" className="!text-mentory-grey-4">
                  {notification.body}
                </Text>
              </button>
            </div>
          ),
          {
            duration: 3000,
          },
        );
      },
    );

    return () => {
      listener.then((listener) => listener.remove());
    };
  }, [navigate, location]);

  return null;
}

function useUpdateFcmToken() {
  const userQuery = useUser();

  useEffect(() => {
    (async () => {
      if (userQuery.data?.ulid == null) {
        return;
      }

      if (!Capacitor.isNativePlatform()) {
        return;
      }

      const permission = await FirebaseMessaging.checkPermissions();
      if (permission.receive === "granted") {
        const { token } = await FirebaseMessaging.getToken();
        await request.post("fcm-token", { json: { token } });
      }
    })();
  }, [userQuery.data?.ulid]);
}

function useNavigateFromNotification() {
  const navigate = useNavigate();
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const navigation = useNavigation();
  const location = useLocation();

  // Listen for notification tap
  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    const listener = FirebaseMessaging.addListener(
      "notificationActionPerformed",
      (event) => {
        const data =
          (event.notification.data as Record<string, string> | null) ?? {};
        if (data.route != null) {
          setNavigateTo(data.route);
        }
      },
    );

    return () => {
      listener.then((listener) => listener.remove());
    };
  }, []);

  // Navigate to notification route if we're currently not navigating
  // and the route is not the current route
  useEffect(() => {
    if (
      navigateTo != null &&
      navigation.state === "idle" &&
      location.pathname !== "/" &&
      location.pathname !== navigateTo
    ) {
      navigate(navigateTo);
      setNavigateTo(null);
    }
  }, [navigateTo, navigate, navigation, location]);
}
